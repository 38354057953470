import { DROPDOWN_WIDTHS } from "@/helpers/constants";
import type { Nullish } from "@/models/common";

declare class ResizeObserver {
  constructor(callback: () => void);
  observe: (target: Element) => void;
  disconnect: () => void;
}

export const observer = ({
  elem1,
  elem2,
  cb
}: {
  elem1: string;
  elem2: string | null | undefined;
  cb: () => void;
}): (() => void) => {
  const observer = new ResizeObserver(() => {
    if (typeof cb === "function") {
      cb();
    }
  });
  let element1 = null;
  let element2 = null;

  if (elem1) {
    element1 = document.getElementById(elem1);
  }
  if (elem2) {
    element2 = document.getElementById(elem2);
  }

  const elements = [element1, element2].filter(Boolean) as Element[];

  elements.forEach((element) => observer.observe(element));

  return () => observer.disconnect;
};

export const contactViaIcon = (key: string | number): string => {
  switch (key) {
    case "1":
      return "telephone-no-color";
    case "2":
      return "email-no-color";
    default:
      return "widget-no-color";
  }
};

export const colorIsDark = (color: string): boolean => {
  // accepts an RGB value string to determine if color is dark or light
  // can be used to determine whether to use dark or light text color on a background color
  const [red, green, blue] = color.split(",").map(Number);
  const isDark = red * 0.299 + green * 0.587 + blue * 0.114 < 186;
  return isDark;
};

/**
 * Returns an object used for styling a pill component
 * @param {Object} options Options containing color (required, RGB representation)
 * and opaque properties (optional).
 * @returns {Object} Object containing color, backgroundColor, and borderStyle
 */
export const getPillStyle = ({
  color,
  opaque
}: {
  color: string;
  opaque?: boolean;
}): Pick<CSSStyleDeclaration, "color" | "backgroundColor" | "borderStyle"> => {
  const textColor = opaque
    ? colorIsDark(color)
      ? "white"
      : "black"
    : `rgba(${color})`;
  const backgroundAttributes = {
    backgroundColor: `rgba(${color}, ${opaque ? "1" : "0.1"})`,
    borderStyle: "none"
  };
  return { color: textColor, ...backgroundAttributes };
};

export const HEIGHT_REGEX_PATTERN = /(\s|^)h(-|eight)/;

export const getDropdownWidth = (
  options: string[],
  checkboxPadding = false,
  min = 224
) => {
  if (!options?.length) {
    return null;
  }
  const longest = [...options].sort((a, b) => b?.length || 0 - a?.length || 0);
  const maxWidth = Math.min(
    longest[0].length * DROPDOWN_WIDTHS.char_width +
      (checkboxPadding
        ? DROPDOWN_WIDTHS.checkbox_item_padding
        : DROPDOWN_WIDTHS.dropdown_padding),
    DROPDOWN_WIDTHS.max_width
  ).toFixed(0);
  return parseFloat(maxWidth) < min ? null : `${maxWidth}px`;
};

export const isTextClamped = (element: HTMLElement | Nullish) => {
  if (!element) {
    return false;
  }

  return element.scrollHeight > element.clientHeight;
};
